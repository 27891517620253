import React, { useState, cloneElement } from "react";

import NewSpeakerService from "../../../../services/NewSpeakerService";
import CheckGitHubService from "../../../../services/CheckGitHubService";


import ModalDialog from "../../../../Components/ModalDialog";
import Loading from "../../../../Components/Loading";

import "./styles.css";

import ImageHome from "../../../../assets/image_home.svg";

function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [institution, setInstitution] = useState("");
  const [theme, setTheme] = useState("");
  const [github_username, setGitHubUserName] = useState("");

  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const [gitError, setGitError] = useState(false)


  const isEmptyStates = () => {
    return (
      !name.length ||
      !email.length ||
      !institution.length ||
      !theme.length ||
      !github_username.length
    );
  };

  const handleForm = async (e) => {
    e.preventDefault();


    const check = await CheckGitHubService.checkGitHub(github_username);

    if(check == false){
      setGitError(true);
      return;
    }else{

    setLoading(true);
    if (!isEmptyStates()) {
      NewSpeakerService.create({
        name,
        email,
        institution,
        theme,
        githublink: `https://github.com/${github_username}`,
      }).then(() => {
        setConfirmed(true);
        setName("");
        setEmail("");
        setGitHubUserName("");
        setInstitution("");
        setTheme("");
        setLoading(false);
      });
    } else {
      setError(true);
      setLoading(false);
    }
  }
  };

  return (
    <>
      <div id="form-section">
        <div className="title-content">
          <h3>QUERO PARTICIPAR</h3>
        </div>
        <div className="text-content">
          <div className="box-content">
            <p>
              Para participar do DEVTALK como orador preencha o formulario a
              seguir, nossa equipe então fará uma avaliação e caso o seu tema
              seja aprovado entraremos em contato.
            </p>
            <img src={ImageHome} alt="DevTalk" />
          </div>
        </div>
        <div className="form-content">
          <form onSubmit={handleForm}>
            <div className="input-box">
              <label id="required-title">
                Todos os campos são obrigatórios
              </label>
            </div>

            <div className="input-box">
              <input
                required
                className="form-control required"
                type="text"
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="input-box">
              <input
                required
                className="form-control required"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="input-box">
              <input
                required
                className="form-control required"
                type="text"
                placeholder="Username do GitHub"
                value={github_username}
                onChange={(e) => setGitHubUserName(e.target.value)}
              />
            </div>

            <div className="input-box">
              <input
                required
                className="form-control required"
                type="text"
                placeholder="Instituição"
                value={institution}
                onChange={(e) => setInstitution(e.target.value)}
              />
            </div>

            <div className="input-box">
              <input
                required
                className="form-control required"
                type="text"
                placeholder="Tema"
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
              />
            </div>

            {loading ? (
              <button disabled type="submit">
                ENVIAR
              </button>
            ) : (
              <button type="submit">ENVIAR</button>
            )}
            <ModalDialog
              situationDialog={confirmed}
              setSituationDialog={setConfirmed}
              title="Informações Enviadas"
              text={`As informações foram enviadas, entraremos em contato com você no futuro!`}
            />
            <ModalDialog
              situationDialog={error}
              setSituationDialog={setError}
              title="Error no envio das informações"
              text={`Por favor preencha todos os campos.`}
            />

              <ModalDialog
              situationDialog={gitError}
              setSituationDialog={setError}
              title="Error na verificação do Username do GitHub"
              text={`Verifique seu Username e tente novamente!`}
            />
          </form>
        </div>
      </div>
      {loading ? <Loading /> : <></>}
    </>
  );
}

export default Form;

import Axios from 'axios';
import { UserNameError } from '../utils/Errors';


class CheckGitHubService {
   
     static async checkGitHub(gitHubLink){
        try{
            await Axios.get(`https://api.github.com/users/${gitHubLink}`);
            return true;

        }catch{
            return false;
        }
    }
}

export default CheckGitHubService;
import React, { useState, useContext, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'

import AuthContext from '../../contexts/Auth'

import UserService from '../../services/UserService'
import Loading from '../../Components/Loading'

import { MDBBtn } from 'mdbreact'

import DevImage from '../../assets/dev_security.svg'

import './styles.css'

function Login() {

  const history = useHistory()
  const { currentUser } = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(currentUser){
      history.push('/admin/dashboard')
    }
  }, [currentUser, history])

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)

    UserService.auth(email, password).then(response => {
      setLoading(false)
      history.push('/admin/dashboard')
    }).catch(err => {
      if(err.name === 'AuthenticError'){
        alert('Usuario ou senha incorreto!')
      }else{
        console.log(err)
      }
      setLoading(false)
      setEmail('')
      setPassword('')
    })

  }

  const isEmptyStates = () => {
    return !email.length || !password.length
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : ''}
      <div id="login-content">
        <div className="form-content">
          <form onSubmit={handleFormSubmit}>
            <h3>Login</h3>
            <input 
              placeholder='Email'
              type="email"
              className="form-control"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <input
              placeholder='Senha'
              type="password"
              className="form-control"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            {isEmptyStates() || loading ? (
              <MDBBtn type='submit' disabled>
                Entrar
              </MDBBtn>
            ) : (
              <MDBBtn type='submit'>
                Entrar
              </MDBBtn>
            )}
              
                <Link to="/" id="back-home">Voltar para home <i className="fas fa-home"/></Link>
              
          </form>

        
        </div>
        <div className="image-content">
          <img src={DevImage} alt="DevTalk"/>
        </div>
      </div>
    </>
  )
}

export default Login
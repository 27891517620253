import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import DevTalkService from '../../services/DevTalkService';

import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';

import Header from './components/Header';
import Form from './components/Form';

import Loading from '../../Components/Loading';
import FooterHome from '../../Components/FooterHome';

import './styles.css';

import ImagemMain from '../../assets/image_main.svg';
import LogoLoopis from '../../assets/logo_loopis.svg';

export default function Home() {
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const [devtalks, setDevTalks] = useState([]);

	useEffect(() => {
		setLoading(true);
		DevTalkService.read().then((response) => {
			setDevTalks(response);
			setLoading(false);
		});
	}, []);

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<div id='home-container'>
					<div id='art-forms'>
						<img
							src={require('../../assets/dev_group.svg')}
							alt=''
						/>
					</div>

					{/* Top  */}

					<Header />

					<div id='top-content'>
						<div className='info-content'>
							<div className='information'>
								<p>
									Entre diálogos e
									aprendizados nos
									moldamos no mundo da
									tecnologia. A
									experiência da
									comunicação e a vontade
									de passar o seu
									conhecimento a diante é
									o que dá vida ao
									DevTalk, um evento para
									disseminar conhecimento
									em uma comunidade sem
									barreiras.
								</p>
								<Link to='/event'>
									Saiba mais
								</Link>
							</div>
						</div>
						<div className='image-content'>
							<img
								className='image-main'
								src={ImagemMain}
								alt='DevTalk'
							/>
							<div className='logo'>
								<img
									src={LogoLoopis}
									alt='Loopis'
								/>
							</div>
						</div>
					</div>

					{/* Top End */}

					{/* List */}

					<div id='list-content'>
						<MDBContainer className='devtalks'>
							<div className='title-content'>
								<h3>ULTIMAS EDIÇÕES</h3>
							</div>
							<MDBRow>
								{devtalks.map((devtalk) => (
									<MDBCol
										md='3'
										key={
											devtalk.docRef
										}>
										<div
											className='devtalk'
											onClick={() =>
												history.push(
													`/specific/${devtalk.docRef}`
												)
											}>
											<img
												src={
													devtalk.image
												}
												alt={
													devtalk.theme
												}
											/>
										</div>
									</MDBCol>
								))}
							</MDBRow>
						</MDBContainer>
					</div>

					{/* List End */}

					<Form />

					<FooterHome />
				</div>
			)}
		</>
	);
}

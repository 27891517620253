import React, { useState, useEffect } from 'react'
import DevTalkService from '../../../../services/DevTalkService'

import './styles.css'

import Loading from '../../../../Components/Loading'
import CardDevTalkInfo from '../CardDevTalkInfo'

import {
  MDBContainer, MDBIcon, MDBLink, MDBRow
} from 'mdbreact'

export default function DevTalkOverview() {

  const [devtalks, setDevTalks] = useState([])
  const [change, modify_change] = useState(true)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    
    setLoading(true)
    DevTalkService.read().then(response => {
      setLoading(false)
      setDevTalks(response)
    })

  }, [change])

  return (
    <>
    {loading ? (
      <Loading className='loading-views'/>
    ) : ''}
    <MDBContainer fluid id='devtalk-overview-content'>
      <h3 className="title-overview">DevTalks</h3>
      <div className="title-content-overview">
        <MDBLink className="btn-add-overview" to="/admin/dashboard/devtalk/new">
          <MDBIcon icon="plus" size="lg"/>
        </MDBLink>
      </div>
      {devtalks.length ? (
        <div className="content-devtalks-overview">
          <MDBRow className='row-content'>
            {devtalks.map(devtalk => (
              <CardDevTalkInfo
                modify_change={modify_change}
                title={devtalk.theme}
                edition={devtalk.edition}
                image_link={devtalk.image}
                devtalk_ref={devtalk.docRef}
                key={devtalk.edition}
              />
            ))}
          </MDBRow>
        </div>
      ) : (
        <div className="content-devtalks-empty">
          <MDBIcon icon='grin-beam' size='2x' className='icon-empty'/>
          <h4>Você ainda não possui devtalks cadastrados!</h4>
        </div>
      )}
      
    </MDBContainer>
    </>
  )
}

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Main from './components/Main'
import OperationsDevTalk from './components/OperationsDevTalk'
import DevTalkOverview from './components/DevTalkOverview'
import Profile from './components/Profile'

export default function Views() {
  return (
    <Switch>
      <Route path="/admin/dashboard" exact component={Main}/>
      <Route path="/admin/dashboard/devtalk" exact component={DevTalkOverview}/>
      <Route path="/admin/dashboard/devtalk/new"  component={OperationsDevTalk}/>
      <Route path="/admin/dashboard/devtalk/:id"  component={OperationsDevTalk}/>
      <Route path="/admin/dashboard/profile" component={Profile}/>
    </Switch>
  )
}

import { FileTypeError, ParameterError, IdRepeatError, UserNameError } from '../utils/Errors'
import Firebase from '../utils/initializeFirebase'
import 'firebase/firestore'
import 'firebase/storage'

import CheckGitHubService from './CheckGitHubService';

class DevTalkNotFound extends Error{
  name='DevTalkNotFound'
}

/**
 * @description DataClass para auxiliar na documentação
 */
// eslint-disable-next-line
class Devtalk {
  edition
  image
  speaker
  summary
  theme
  gitHubLink
}

/**
 * @author Daniel Dantas <daniel.dantas.developer@gmail.com>
 * @description Classe responsável pelas operações de CRUD dos devtalks
 */
class DevTalkService {
  /**
   * @function create
   * @param {Devtalk} devtalk
   * @throws {ParameterError, IdRepeatError, FileTypeError}
   */
  static async create({ edition, image, speaker, summary, theme, gitHubLink }){

    const check = await CheckGitHubService.checkGitHub(gitHubLink);

    if(check == false){
      throw new UserNameError('GitHub username not found!');
    }

    if(typeof(edition) !== 'number' || typeof(speaker) !== 'string' ||
      typeof(summary) !== 'string' || typeof(theme) !== 'string'){
        throw new ParameterError('Error in parameter')
    }

    const existsEdition = await Firebase.firestore().collection('devtalks').where('edition', '==', edition).get().then(response => {
      return response.docs.length
    })

    if(existsEdition){
      throw new IdRepeatError('Edition already registered')
    }

    console.log(image);

    if(!image){
      throw new FileTypeError('Attribute image is not found')
    }else if (!image.type.startsWith('image')){
      throw new FileTypeError('Only images should be used')
    }

    image = await Firebase.storage().ref(`devtalks/${theme}#${edition}`).put(image).then(async snapshot => {
      return await snapshot.ref.getDownloadURL()
    })

    return await Firebase.firestore().collection('devtalks').add({
      edition,
      image,
      speaker,
      summary,
      theme,
      // gitHubLink: `https://github.com/${gitHubLink}` // quebrado (gerava prefixo infinito)
      gitHubLink: gitHubLink.startsWith('https://github.com/')? gitHubLink: `https://github.com/${gitHubLink}`
    }).catch(err => {
      throw err
    })
  }

  /**
   * @function count
   * @returns {number}
   */
  static async count(){
    return (await Firebase.firestore().collection('devtalks').get()).size
  }

  /**
   * @function read
   * @returns {Promise<Devtalk[]>}
   */
  static async read(){

    const response = []

    await Firebase.firestore().collection('devtalks').orderBy('edition').get().then(devtalks => {
      // eslint-disable-next-line array-callback-return
      devtalks.docs.map(devtalk => {
        response.push({
          docRef: devtalk.id,
          edition: devtalk.data().edition,
          speaker: devtalk.data().speaker,
          image: devtalk.data().image,
          summary: devtalk.data().summary,
          theme: devtalk.data().theme,
          gitHubLink: devtalk.data().gitHubLink || null
        })
      })
    }).catch(err => {
      throw err 
    })

    return response.reverse()

  }

  /**
   * @function lastEdition
   * @description Retorna a ultima edição do devtalk que foi cadastrada
   * @returns {number}
   */
  static async lastEdition(){

    let last = 0

    await this.read().then(devtalks => {
      if(devtalks[0]){
        last = devtalks[0].edition
      }else{
        last = 0
      }
    })
    return last

  }

  /**
   * @function delete
   * @param {string} docRef
   * @throws {DevTalkNotFound}
   */
  static async delete(docRef){
    await Firebase.firestore().collection('devtalks').doc(docRef).delete().catch(err => {
     throw new DevTalkNotFound('Devtalk not found')
    })
  }

  /**
   * @function update
   * @param {Devtalk} devtalk
   * @throws {ParameterError, FileTypeError}
   */
  static async update({docRef, edition, image, speaker, summary, theme, gitHubLink }){

    console.log(docRef)

    if(typeof(edition) !== 'number' || typeof(speaker) !== 'string' ||
      typeof(summary) !== 'string' || typeof(theme) !== 'string' || typeof(docRef) !== 'string'){
        throw new ParameterError('Error in parameter')
    }

    if(!(typeof(image) === 'string')){

      if(!(image.type.startsWith('image'))){
        throw new FileTypeError('Only images should be used')
      }

      image = await Firebase.storage().ref(`devtalks/${theme}#${edition}`).put(image).then(async snapshot => {
        return await snapshot.ref.getDownloadURL()
      })
    }
    return await Firebase.firestore().collection('devtalks').doc(docRef).update({
      edition,
      image,
      speaker,
      summary,
      theme,
      // gitHubLink: `https://github.com/${gitHubLink}` // quebrado (gerava prefixo infinito)
      gitHubLink: gitHubLink.startsWith('https://github.com/')? gitHubLink: `https://github.com/${gitHubLink}`
    }).catch(err => {
      throw err
    })
  }

  /**
   * @function search
   * @param {string} docRef
   * @returns {Promise<Devtalk>}
   * @throws {ParameterError}
   */
  static async search(docRef) {

    if(typeof(docRef) !== 'string'){
      throw new ParameterError('Error in parameter')
    }

    return await Firebase.firestore().collection('devtalks').doc(docRef).get().then(response => {
      return response.data()
    })

  }

}

export default DevTalkService

import { ParameterError } from '../utils/Errors'
import Firebase from '../utils/initializeFirebase'
import 'firebase/firestore'

import CheckGitHubService from './CheckGitHubService';

class NewSpeakerNotFound extends Error{
  name="NewSpeakerNotFound"
}

/**
 * @description DataClass para auxiliar na documentação
 */
// eslint-disable-next-line
class NewSpeaker{
  email
  institution
  name
  theme
  githublink
}

/**
 * @author Daniel Dantas <daniel.dantas.developer@gmail.com>
 * @description Classe que cuida das operações de CRUD de novos oradores
 */
class NewSpeakerService {

  /**
   * @function create
   * @param {NewSpeaker} newSpeaker
   * @throws {ParameterError}
   */
  static async create ({email, institution, name, theme, githublink}) {

    if(typeof(email) !== 'string' || typeof(institution) !== 'string' ||
     typeof(name) !== 'string'|| typeof(theme) !== 'string' || typeof(githublink) !== 'string'){
      throw new ParameterError('Error in parameter')
    }

    const filed = false

    return await Firebase.firestore().collection('new_speakers').add({
      email,
      institution,
      name,
      theme,
      githublink,
      filed
    }).catch(err => {
      throw err
    })
  }

  /**
   * @function read
   * @returns {Promise<NewSpeaker>}
   */  
  static async read (){
    const response = []

    await Firebase.firestore().collection('new_speakers').get().then(speakers => {
      // eslint-disable-next-line array-callback-return
      speakers.docs.map(speaker => {
        response.push({
          docRef: speaker.id,
          name: speaker.data().name,
          email: speaker.data().email,
          institution: speaker.data().institution,
          theme: speaker.data().theme,
          githublink: speaker.data().githublink,
          filed: speaker.data().filed
        })
      })
    }).catch(err => {
      throw err
    })

    return response.filter(speaker => {
      return !speaker.filed
    })
  }

  /**
   * @function update
   * @param {string} docRef 
   * @param {NewSpeaker} newSpeaker
   * @throws {ParameterError} 
   */
  static async update (docRef, {email, institution, name, theme, githublink,filed}) {

    if( typeof(docRef) !== 'string' || typeof(email) !== 'string' || typeof(institution) !== 'string' ||
     typeof(name) !== 'string'|| typeof(theme) !== 'string' || typeof(githublink) !== 'string'){
      throw new ParameterError('Error in parameter')
    }

    return await Firebase.firestore().collection('new_speakers').doc(docRef).update({
      email, 
      institution, 
      name, 
      theme,
      githublink,
      filed
    }).then(() => {
      return true
    }).catch(err => {
      throw err
    })
  }

  /**
   * @function delete
   * @param {string} docRef 
   * @throws {DevTalkNotFound}
   */
  static async delete (docRef) {
    Firebase.firestore().collection('new_speakers').doc(docRef).delete().catch(err => {
      throw new NewSpeakerNotFound('New speaker not found')
    })
  }

  /**
   * @function search
   * @param {string} docRef
   * @returns {Promise<NewSpeaker>}
   * @throws {NewSpeakerNotFound} 
   */
  static async search (docRef) {
    
    return await Firebase.firestore().collection('new_speakers').doc(docRef).get().then(response => {
      return response.data
    }).catch(err => {
      throw new NewSpeakerNotFound('NewSpeaker not found')
    })

  }

}
 export default NewSpeakerService
import React, { useEffect, useState } from 'react'
import DevTalkService from '../../../../services/DevTalkService'
import NewSpeakerService from '../../../../services/NewSpeakerService'

import Loading from '../../../../Components/Loading'

import './styles.css'

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
}from 'mdbreact'

import CardInformation from '../CardInformation'

import ToFileModal from '../ToFileModal'

export default function Main() {

  const [devtalksSize, setDevtalksSize] = useState(0)
  const [newSpeakers, setNewSpeakers] = useState([])

  const [loading, setLoading] = useState(false)

  const [change, setChange] = useState(0)

  useEffect(() => {
    setLoading(true)
    DevTalkService.count().then(size => {
      setDevtalksSize(size)
    })

    NewSpeakerService.read().then(spakers => {
      setNewSpeakers(spakers)
    })
    setLoading(false)
  }, [devtalksSize, change])

  return (
    <>
      {loading ? (
        <Loading className='loading-views'/>
      ) : ''}
      <MDBContainer id='main-content'>
        <MDBRow className='row-content'>
          <MDBCol md="6">
            <CardInformation
              icon="atlas"
              number={devtalksSize}
              title="DevTalks Cadastrados"
            />
          </MDBCol>
          <MDBCol md="6">
            <CardInformation
              icon="address-card"
              number={newSpeakers.length}
              title="Novos Oradores"
            />
          </MDBCol>
          <MDBCol md="12">
            <div className="table-main-content">
              <h3 className="title-table-main">Novos Oradores</h3>
              <div className="table-content">
                  <table className="table table-bordered table-striped mb-0">
                    <thead className="table-row-title">
                      <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Email</th>
                        <th scope="col">Tema</th>
                        <th scope="col">Arquivar</th>
                      </tr>
                    </thead>
                    <tbody className='body-table-speakers'>
                      {newSpeakers.length ? (
                        newSpeakers.map(speaker => (
                          <tr key={speaker.theme}>
                            <th scope="row"><a href={speaker.githublink}>{speaker.name}</a></th>
                            <td><a href={`mailto:${speaker.email}`}>{speaker.email}</a></td>
                            <td>{speaker.theme}</td>
                            <td className='button-to-file-content'>
                              <ToFileModal newSpeaker={speaker} stateChange={change} functionUpdate={setChange}/>
                            </td>
                          </tr>
                        ))
                      ) : (
                          <td colSpan='4' style={{padding: '0px'}}>
                            <div className="empty-speakers-content">
                              <MDBIcon icon='grin-beam' size='2x'/>
                              <h4>Sem novos oradores!</h4>
                            </div>
                          </td>
                      )}
                    </tbody>
                  </table>
                
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  )
}

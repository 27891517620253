import React, { useState } from 'react'

import './styles.css'

import { Link } from 'react-router-dom'
import { MDBModal, MDBModalHeader, MDBModalBody, MDBIcon } from 'mdbreact'

import NewSpeakerService from '../../../../services/NewSpeakerService'

export default function ToFileModal ({ newSpeaker, functionUpdate, stateChange }) {

    const [modalActive, setModalActive] = useState(false)

    const toggle = (e) => {

        if(e){
            e.preventDefault()
        }
        
        setModalActive(!modalActive)
    }

    const confirmAction = async () => {

        newSpeaker.filed = true

        await NewSpeakerService.update(newSpeaker.docRef, newSpeaker)
        functionUpdate(stateChange + 1)
        toggle()
    }

    return (
        <>
            <Link to='' onClick={toggle} className='button-tofile'>
                <MDBIcon icon='file-export'/>
            </Link>
            <MDBModal className='modal-tofile' isOpen={modalActive} toggle={toggle}>
                <MDBModalHeader toggle={toggle}>Arquivar</MDBModalHeader>
                <MDBModalBody>
                    Deseja realmente arquivar a solicitação desse orador?
                </MDBModalBody>
                <div className='modal-footer-tofile'>
                    <button className='cancel-button-tofile' onClick={toggle}>Cancelar</button>
                    <button className='confirm-button-tofile' onClick={confirmAction}>Confirmar</button>
                </div>
            </MDBModal>
        </>
    )
}
import React, { useState } from 'react'

import DevTalkService from '../../../../services/DevTalkService'

import './styles.css'

import { MDBModal, MDBModalHeader, MDBModalBody, MDBIcon } from 'mdbreact'

export default function ExclusionModal ({ devtalk_ref, modify_change }) {

    const [modalActive, setModalActive] = useState(false)

    const toggle = () => {
        setModalActive(!modalActive)
    }

    const confirmAction = async () => {
        await DevTalkService.delete(devtalk_ref).then(() => {
            modify_change(devtalk_ref)
            toggle()
        })
    }

    return (
        <>
            <button className='btn-modal-exclusion' onClick={toggle}><MDBIcon icon='trash-alt'/></button>
            <MDBModal className='modal-exclusion' isOpen={modalActive} toggle={toggle}>
                <MDBModalHeader toggle={toggle}>Exclusão</MDBModalHeader>
                <MDBModalBody>
                    Deseja realmente excluir o devtalk?
                </MDBModalBody>
                <div className='modal-footer-exclusion'>
                    <button className='cancel-button-exclusion' onClick={toggle}>Cancelar</button>
                    <button className='confirm-button-exclusion' onClick={confirmAction}>Confirmar</button>
                </div>
            </MDBModal>
        </>
    )
}
import { AuthenticError, ParameterError } from '../utils/Errors'
import Firebase from '../utils/initializeFirebase'
import 'firebase/auth'
// eslint-disable-next-line
import { User } from 'firebase'

/**
 * @author Daniel Dantas <daniel.dantas.developer@gmail.com>
 * @description Classe responsável por cuidar das funcionalidades dos Usuarios
 */
class UserService{
  
  /**
   * @function auth
   * @param {string} email 
   * @param {string} password 
   * @returns {User}
   * @throws {ParameterError, AuthenticError}
   */
  static async auth (email, password) {

    if(typeof(email) !== 'string' || typeof(password) !== 'string'){
      throw new ParameterError('Error in parameter')
    }

    return await Firebase.auth().signInWithEmailAndPassword(email, password).then(response => {
      return response.user
    }).catch( err => {
      throw new AuthenticError('Error in authentication')
    })
  }

  static async logout (){
    Firebase.auth().signOut().catch(err => {
      throw new Error('Error in logout')
    })
  }

  /**
   * @function modifyPassword
   * @param {string} newPassword 
   */
  static async modifyPassword (newPassword) {
    const user = await Firebase.auth().currentUser

    return await user.updatePassword(newPassword).catch(err => {
      console.log(err)
      throw new Error('Error in modify password')
    })

  }

}
export default UserService
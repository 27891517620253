import React, { useState, useEffect } from 'react'
import { createContext } from 'react'

import Firebase from '../utils/initializeFirebase'

const AuthContext = createContext({currentUser: {}})

export const AuthProvider = ({children}) => {

  const [currentUser, setCurrentUser] = useState({})

  useEffect(() => {
    Firebase.auth().onAuthStateChanged(async user => {
      setCurrentUser(user)
    })
  },[])

  return (
    <AuthContext.Provider value={{currentUser}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext

export class FileTypeError extends Error{
  name='FileTypeError'
}

export class AuthenticError extends Error{
  name='AuthenticError'
}

export class ParameterError extends Error {
  name='ParameterError'
}

export class IdRepeatError extends Error {
  name='IdRepeatError'
}

export class UserNameError extends Error{
  name="UserNameError"
}
import React from 'react'

import ExclusionModal from '../ExclusionModal'

import {
  MDBCard,
  MDBView,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBLink
} from 'mdbreact'

import './styles.css'

export default function CardDevTalkInfo({image_link, title, edition, devtalk_ref, modify_change}) {
  return (
    <>
      <MDBCol md="3">
        <MDBCard wide cascade className="card-devtalk-info-content">
          <MDBView cascade>
            <MDBCardImage
              hover
              overlay='white-slight'
              className='card-img-top'
              src={image_link}
              alt='Card cap'
            />
          </MDBView>

          <MDBCardBody cascade className='text-center'>
            <MDBCardTitle className='card-title'>
              <strong>{title}</strong>
            </MDBCardTitle>

            <p className='font-weight-bold blue-text'>Edição #{edition}</p>

            <div md='12' className='content-operations'>
              <MDBLink className='link-edit' to={`/admin/dashboard/devtalk/${devtalk_ref}`}>
                <MDBIcon icon="edit"/>
              </MDBLink>
              <ExclusionModal devtalk_ref={devtalk_ref} modify_change={modify_change}/>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </>
  )
}

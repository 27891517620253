import React from 'react'

import './styles.css'

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
} from 'mdbreact'

export default function CardInformation({ number, title, icon}) {
  return (
    <MDBCard className="cascading-admin-card card-content">
      <div className="admin-up">
        <MDBIcon icon={icon} className="icon-card"/>
        <div className="data">
          <p>{title}</p>
          <h4>
            <strong>{number}</strong>
          </h4>
        </div>
      </div>
      <MDBCardBody />
    </MDBCard>
  )
}

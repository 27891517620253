import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import UserService from '../../../../services/UserService'

import './styles.css'
import Loading from '../../../../Components/Loading'

export default function Profile () {

  const history = useHistory()

  const [newPass, setNewPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')

  const [loading, setLoading] = useState(false)

  const verify = () => {
    return newPass === confirmPass && 
      newPass.length && confirmPass.length
  }

  const handleFormAction = async (e) => {
    e.preventDefault()

    setLoading(true)
    await UserService.modifyPassword(newPass).then( async () => {
      alert('Senha alterada com sucesso!')
      setLoading(false)

      await UserService.logout()

      history.push('/admin/login/')
    }).catch(err => {
      alert('A senha deve ter pelo menos 6 caracteres!')
      setLoading(false)
    })

  }

  return (
    <>
      {loading ? (
        <Loading className='loading-views' />
      ) : ''}
      <div id="profile">
        <h3>Mudança de senha</h3>
        <div className="password-content">
          <form onSubmit={handleFormAction}>
            <input 
              className='form-control form-pass'
              type='password'
              placeholder='Nova senha'
              value={newPass}
              onChange={e => setNewPass(e.target.value)}
            />
            <input 
              className='form-control form-pass'
              type='password'
              placeholder='Confirmar nova senha'
              value={confirmPass}
              onChange={e => setConfirmPass(e.target.value)}
            />
              {verify()  ? (
                <button type='submit' className='btn-pass'>
                  Salvar
                </button>
              ) : (
                <button type='submit' className='btn-pass' disabled>
                  Salvar
                </button>
              )}
          </form>
          
        </div>
      </div>
    </>
  )
}

import React from 'react'
import Routes from './routes'

import { AuthProvider } from './contexts/Auth'

import './global.css'
export default function App() {
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  )
}

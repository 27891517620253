import React, { useContext } from 'react'

import { Route, Redirect } from 'react-router-dom'

import AuthContext from '../../contexts/Auth'

export default function PrivateRouter({ component: Component, ...rest }) {
  
  const { currentUser } = useContext(AuthContext)
  
  return (
    <Route 
      {...rest}
      render={
        currentUser ? (
          () => <Component />
        ) : (
          () => <Redirect to="/admin/login"/>
        )
      }
    
    />
  )
}

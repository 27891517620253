import React from 'react';
import { Link } from 'react-router-dom';

import DevImage from '../../assets/dev_image.svg';
import DevLogo from '../../assets/dev_logo.svg';

import './styles.css';

export default function EventInformation() {
	return (
		<div id='event-container'>
			<div id='information-container'>
				<div className='information-box'>
					<div id='information-text'>
						<label id='information-title'>
							Sobre o evento
						</label>
						<div id='information-body'>
							O DevTalk é um projeto
							idealizado e realizado pela
							Loopis Jr. com o intuito de
							criar um ambiente onde
							desenvolvedores e amantes de
							tecnologia possam interagir e
							trocar conhecimentos, seja por
							meio de uma apresentação em
							formato de aula aberta para o
							público ou como uma roda de
							conversa e discussão sobre um
							determinado assunto. A
							disseminação desse conhecimento
							é uma via de mão dupla, pois até
							alguém com pouquíssima
							experiência na área pode ensinar
							algo que uma pessoa a anos na
							área não sabe, e vise-versa.
							Este é um evento aberto, onde
							qualquer pessoa que sinta
							interesse em compartilhar algum
							conhecimento sobre tecnologia
							pode participar, sem restrição
							ideológica, política, étnica,
							racial ou de gênero.
						</div>
					</div>
				</div>

				<div className='information-box'>
					<div id='photo-container'>
						<img
							id='dev-imag'
							src={DevImage}
							alt='DEV-IMAG'
						/>
					</div>
				</div>
			</div>

			<div id='logo-site-devtalk'>
				<div id='div-back'>
					<Link to='/' id='back'>
						<i className='fas fa-angle-left' />
						Voltar
					</Link>
				</div>

				<img
					id='dev-imag-logo'
					src={DevLogo}
					alt='DEV-LOGO'
				/>
			</div>
		</div>
	);
}

import React,{ useState } from 'react';

import { Link } from 'react-router-dom'

import { FaBars } from 'react-icons/fa'

import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText } from '@material-ui/core'

import DevLogo from '../../../../assets/dev_logo.svg'
import LogoLoopis from '../../../../assets/logo_loopis.svg'

import './styles.css';

function Header() {

  const [isOpen, setIsOpen] = useState(false)

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      ((event).key === 'Tab' ||
        (event).key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open)
  };


  return (
    <>
      <div id="header-mobile">
         <AppBar className='app-bar'>
          <Toolbar>
            <IconButton className="icon-button" edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <FaBars />
            </IconButton>
            <Drawer anchor='left' open={isOpen} onClose={toggleDrawer(false)}>
              <div
                style={{width: '70vw', height: '100vh', backgroundColor: '#01062A'}}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <List>
                  <a href="#list-content">
                    <ListItem button>
                      <ListItemText primary="EDIÇÕES ANTERIORES" style={{textAlign: 'center', color: '#fff'}}/>
                    </ListItem>
                  </a>
                  <a href="#form-section">
                    <ListItem button>
                      <ListItemText primary="QUERO PARTICIPAR" style={{textAlign: 'center', color: '#fff'}}/>
                    </ListItem>
                  </a>
                </List>
                <div className="logo-header-content-mobile">
                  <img className="logo-header-mobile" src={LogoLoopis} alt="Loopis"/>
                </div>
              </div>
            </Drawer>
            <div className="logo-content-bar">
              <img src={DevLogo} alt="devtalk"/>
            </div>
          </Toolbar>
          
          </AppBar>
      </div>
      <div id="header">
        <div className="logo-content">
          <Link to='/'>
            <img src={DevLogo} alt="DevTalk"/>
          </Link>
        </div>
        <div className="links-content">
          <a href="#list-content">EDIÇÕES ANTERIORES</a>
          <a href="#form-section">QUERO PARTICIPAR</a>
        </div>
      </div>
    </>
  )
}

export default Header;
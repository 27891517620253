import React from 'react'

import './styles.css'

import SideBar from './components/SideBar'

import Views from './routes'

export default function Dashboard() {

  return (
    <>
      <div id="dash">
        <SideBar />
        <main id="content">
          <Views />
        </main>
      </div>
    </>
  )
}

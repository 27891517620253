import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import PrivateRouter from './Components/PrivateRouter'

import Home from './pages/Home'
import NotFound from './pages/NotFound'
import EventInformation from './pages/EventInformation'
import EventSpecificInformation from './pages/EventSpecificInformation'

// Paginas do administrador
import Login from './pages/Login'
import DashBoard from './pages/Dashboard'


export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/event" component={EventInformation}/>
        <Route path="/specific/:id" component={EventSpecificInformation}/>


        {/* Rotas de paginas administradoras */}
        <Route exact path='/admin' render={() => <Redirect to='/admin/dashboard'/>}/>
        <Route path="/admin/login" component={Login}/>
        <PrivateRouter path="/admin/dashboard" component={DashBoard}/>

        {/* Para Rotas inexistentes */}
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

import React from 'react';

import './styles.css'

export default function Loading ({className=''}) {
  return (
    <div className={`loading-content ${className.slice('.')}`}>
      <div className="spinner-border text-primary fast" role="status">
          <span className="sr-only loading-span">Loading...</span>
      </div>
    </div>
  )
}
import React from 'react'

import './styles.css'


export default function NotFound() {
  return (
    <div id="notfound">
      <center>
        <h1>Pagina não encontrada!</h1>
        <a>Lamentamos, mas não foi possível encontrar a página solicitada.</a><br></br>
        <strong><a href="/"> Clique aqui para voltar a pagina inicial.</a></strong>
      </center>
    </div>
    
  )
}
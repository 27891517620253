import firebase from 'firebase/app';

import ConfigFirebaseProduction from '../config/firebase.json'
import ConfigFirebaseDevelopment from '../config/developmentFirebase.json'

let config = {}

if(process.env.NODE_ENV === "development"){
    config = ConfigFirebaseDevelopment
}else {
    config = ConfigFirebaseProduction
}

export default firebase.initializeApp(config)
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import DevTalkService from '../../services/DevTalkService'

import DevLogo from '../../assets/dev_logo.svg';
import GitHubLogo from '../../assets/github-logo.svg';

import Loading from '../../Components/Loading'

import {
    MDBIcon
} from 'mdbreact'

import './styles.css'

export default function EventSpecificInformation(){

    const [devtalk, setDevtalk] = useState(null)

    const { id } = useParams()

    useEffect(() => {
        DevTalkService.search(id).then(response => {
            if(response != null) {
                setDevtalk(response)
            }
            else{
                window.location.href = '/pagina-de-redirecionamento'
            }
            
        })
        
    }, [id])

    return(
        <>
            {!devtalk ? (
                <Loading />
            ) : (
                <div id="especific-information">
                    <Link to='/' className='link-back'>
                        <MDBIcon icon="angle-left"/> Voltar
                    </Link>
                    <div className="sections">
                        <div className="section-info">
                            <h3>DEVTALK #{devtalk.edition}</h3>
                            <div className="section-text-info">
                                <div className='item-text'>
                                    <span>Tema: </span>
                                    {devtalk.theme}
                                </div>
                                <div className='item-text'>
                                <span>Orador: </span>
                                {devtalk.speaker}

                                {devtalk.gitHubLink ? <a href={devtalk.gitHubLink}>
                                    <img src={GitHubLogo} alt="GitHub Icon"/>
                                    </a>  : null}
                              
                                </div>

                                <div className='item-text'>
                                    <span>Resumo do evento: </span>
                                    <br/>
                                    <p>
                                        {devtalk.summary}
                                    </p>
                                </div>
                                
                            </div>
                            
                        </div>
                        <div className="section-banner-content">
                            <img src={devtalk.image} alt={devtalk.edition}/>
                        </div>
                    </div>
                    <div className="logo-content">
                        <Link to='/'>
                            <img src={DevLogo} alt="DevTalk"/>
                        </Link>
                        
                    </div>
                </div>
            )}
        
        </>
    )
}
import React from 'react';

import LogoLoopis from '../../assets/logo_loopis.svg'

import './styles.css';
import { Link } from 'react-router-dom';

export default function FooterHome(){
    return(
        <div id="footer-home-container">
            <div id="logo-box" className="box">
                <img src={LogoLoopis} alt="DEVTALK - LOGO"/>
            </div>

            <div id="center-box" className="box">  

                <div id="contact-container">
                    <h4>Contato</h4>
                    <p>loopisjr@gmail.com</p>
                </div>

                <div id="social-container">    
                    <h4>Social</h4>

                    <div id="icons-container">
                        <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/loopisjr" className="action-social-button">
                        <i className="fab fa-instagram"></i>
                        </a>

                        <a rel="noopener noreferrer" target="_blank" href="https://github.com/loopisjr" className="action-social-button">
                        <i className="fab fa-github"></i>
                        </a>
                    </div>    
                </div>

            </div>
            <div id="admin-container" className="box">
                <h4>Administradores</h4>
                <Link to="admin/login" id="admin-action">Acessar aba de gerenciamento da página</Link>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react'
import DevTalkService from '../../../../services/DevTalkService'

import Loading from '../../../../Components/Loading'

import {
  useParams,
  Link,
  useHistory
} from 'react-router-dom'

import './styles.css'

import {
  MDBIcon
} from 'mdbreact'

export default function OperationsDevTalk() {

  const [docRef, setDocRef] = useState('')
  const [oldImage, setOldImage] = useState('')

  const [theme, setTheme] = useState('')
  const [speaker, setSpeaker] = useState('')
  const [image, setImage] = useState(null)
  const [summary, setSummary] = useState('')
  const [gitHubLink, setGitHubLink] = useState('');

  const [sugestionEdition, setSugestEdition] = useState(0)
  const [edition, setEdition] = useState('')

  const [loading, setLoading] = useState(false)

  const { id } = useParams()
  
  const history = useHistory()

  useEffect(() => {
    if(id){
      setLoading(true)
      DevTalkService.search(id).then(devtalk => {
        setTheme(devtalk.theme)
        setSpeaker(devtalk.speaker)
        setOldImage(devtalk.image)
        setImage(null)
        setSummary(devtalk.summary)
        setEdition(devtalk.edition)
        setSugestEdition(devtalk.edition)
        setGitHubLink(devtalk.gitHubLink)
        setDocRef(id)
        setLoading(false)
      })

    }else{
      DevTalkService.lastEdition().then(edition => {
        setSugestEdition(edition + 1)
      })
    }
  }, [id])


  const isEmptyStates = () => {
    if( !theme.length || !speaker.length || (!image && !oldImage) || 
        !summary.length ){
      return true
    }
    return false
  }

  const handleFormNew = async (event) => {
    event.preventDefault()
    
        setLoading(true)
        DevTalkService.create({
          edition: (edition ? edition : sugestionEdition),
          theme,
          image,
          speaker,
          summary,
          gitHubLink
        }).then(() => {
          alert('DevTalk cadastrado com sucesso!')
          setLoading(false)
          history.push('/admin/dashboard/devtalk')
        }).catch(err => {
          if(err.name === 'IdRepeatError'){
            alert('Essa edição já foi cadastrada!')
          }else if (err.name === 'FileTypeError'){
            console.log(err)
            alert('Tipo de arquivo invalida tente apenas imagens!')
            setImage(null)
          }else if(err.name === 'UserNameError'){
            alert('Username do GitHub não encontrado!')
          }
          setLoading(false)
        })

    

    

  }

  const handleFormEdit = async (event) => {
    event.preventDefault()

    setLoading(true)

    await DevTalkService.update({
      theme,
      speaker,
      summary,
      edition: (edition ? edition : sugestionEdition),
      docRef,
      image: image ? image : oldImage,
      gitHubLink
    }).then(() => {
      alert('Editado com sucesso!')
      history.push('/admin/dashboard/devtalk')
      setLoading(false)
    }).catch(err => {
      console.log(err)  
      if(err.name === 'FileTypeError'){
        alert('Arquivo de imagem não suportado! Tente imagens png ou jpeg!')
        setImage(null)
      }
      setLoading(false)

    })

  }


  return (
    <>
      {loading ? (
        <Loading className='loading-views'/>
      ) : (
        ''
      )}
      <Link to="/admin/dashboard/devtalk/" className="link-back-operations" >
        <MDBIcon icon="angle-left" className="icon-link-back"/>
        Voltar
      </Link>
      <div className="form-new-content">
        <h3 className="title-form-new">{id ? `Editando DevTalk, Edition #${sugestionEdition}`  : `Novo DevTalk, Edition #${sugestionEdition}`}</h3>
        <form onSubmit={id ? handleFormEdit : handleFormNew}>
          
          <input 
            type='number'
            className="form-control input-form-new"
            placeholder="Edição específica"
            value={edition}
            onChange={e => {setEdition(parseInt(e.target.value)); setSugestEdition(e.target.value)}}
          />

          <input 
            className="form-control input-form-new"
            placeholder="Tema"
            value={theme}
            onChange={e => setTheme(e.target.value)}
          />

          <input 
            className="form-control input-form-new"
            placeholder="Orador"
            value={speaker}
            onChange={e => setSpeaker(e.target.value)}
          />

          <input 
            className="form-control input-form-new"
            placeholder="Username do Github do Orador"
            value={gitHubLink}
            onChange={e => setGitHubLink(e.target.value)}
          />
          
          <label htmlFor="input-file-new">
            <div className="input-file-new">
              {image ? (
                <>
                  <MDBIcon className="icon-camera" icon="image"/>
                  {image.name}
                </>
              ) : (
                <>
                  <MDBIcon className="icon-camera" icon="camera"/>
                  Selecionar a imagem
                </>
              )}
              <input
                id="input-file-new"
                className="file-hidden"
                type="file"
                onChange={e => setImage(e.target.files[0])}
              />
            </div>
          </label>
                
          <textarea
            className="form-control input-form-new"
            style={{height: '150px'}}
            placeholder="Resumo do evento"
            value={summary}
            onChange={e => setSummary(e.target.value)}
          />
          
          {isEmptyStates() || loading ? (
            <button type="submit" disabled className="btn-form-new">{id ? 'Editar' : 'Cadastrar'}</button>
          ): (
            <button type="submit" className="btn-form-new">{id ? 'Editar' : 'Cadastrar'}</button>
          )}

        </form>
        </div>
    </>
  )
}

import React, { useState } from 'react';
import { MDBListGroup, MDBListGroupItem, MDBIcon } from 'mdbreact';
import { NavLink, useHistory } from 'react-router-dom';

import UserService from '../../../../services/UserService'

import './styles.css'

import logo from '../../../../assets/dev_logo.svg'

export default function SideBar() {
  
  const [isOpen, setIsOpen] = useState(false)

  const history = useHistory()

  const logoutAction = async (event) => {

    event.preventDefault()

    await UserService.logout()

    history.push('/admin/login')

  }

  return (
    <>
      <div className="mobile-side-content">
        <div className="nav-mobile">
          <button className="btn-mobile-nav" onClick={() => setIsOpen(!isOpen)}>
            <MDBIcon icon="bars" size="2x"/>
          </button>
        </div>
      </div>
      <div className={`sidebar-fixed position-fixed ${!isOpen ? 'side-content' : ''}`}>
          <div className="close-btn-content">
            <button className="btn-mobile-close" onClick={() => {setIsOpen(!isOpen)}}>
              <MDBIcon  icon="times-circle" size="2x"/>
            </button>
          </div>

          <NavLink to='/admin/dashboard' className="logo-wrapper waves-effect side-logo">
            <img alt="MDB React Logo" className="img-fluid" src={logo}/>
          </NavLink>
          <MDBListGroup className="list-group-flush">
              <NavLink exact={true} to="/admin/dashboard" className="group-item" activeClassName="activeClass">
                  <MDBListGroupItem>
                      <MDBIcon icon="home" className="mr-3"/>
                      Home
                  </MDBListGroupItem>
              </NavLink>
              
              <NavLink to="/admin/dashboard/devtalk" className="group-item" activeClassName="activeClass">
                  <MDBListGroupItem>
                      <MDBIcon icon="atlas" className="mr-3"/>
                      DevTalk
                  </MDBListGroupItem>
              </NavLink>
              <NavLink to="/admin/dashboard/profile" className="group-item" activeClassName="activeClass">
                <MDBListGroupItem>
                    <MDBIcon icon="user-alt" className="mr-3"/>
                    Mudança de Senha
                </MDBListGroupItem>
                <NavLink to="" className="group-item" activeClassName="group-item">
                  <MDBListGroupItem>
                      <MDBIcon icon="power-off" className="mr-3"/>
                      Pagina Inicial
                  </MDBListGroupItem>
              </NavLink>
              </NavLink>
              <NavLink to="/admin/login" className="logout-item" onClick={logoutAction}>
                <MDBListGroupItem>
                  <MDBIcon icon="sign-out-alt" className="mr-3"/>
                  Sair
                </MDBListGroupItem>
              </NavLink>
          </MDBListGroup>
      </div>
    </>
  )
}